<template>
  <div class="student">
    <div class="search">
      <van-search
        v-model="search_ward"
        show-action
        shape="round"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="search" class="seachStyle">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="content" v-if="is_show == true">
      <div class="eyeType">
        <div class="title">
          <span class="biu"></span>
          <div class="text">眼病类型</div>
        </div>
        <div class="eyeTypeList">
          <van-swipe class="my-swipe" :loop="false"  @change="swipeChange">
            <van-swipe-item>
              <van-grid square :border="false" :column-num="5">
                <van-grid-item
                  @click="classItemList(item)"
                  v-for="item in cate_list"
                  :key="item.id"
                >
                  <template #text>
                    <div class="txt">{{ item.title }}</div>
                  </template>
                  <template #icon>
                    <img :src="item.img" class="icon" alt="" />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
            <van-swipe-item>
              <van-grid square :border="false" :column-num="5">
                <van-grid-item
                  @click="classItemList(item)"
                  v-for="item in cate_list"
                  :key="item.id"
                >
                  <template #text>
                    <div class="txt">{{ item.title }}</div>
                  </template>
                  <template #icon>
                    <img :src="item.img" class="icon" alt="" />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
            <!-- <van-swipe-item>
              <van-grid square :border="false" :column-num="4">
                <van-grid-item
                  @click="classItemList(item)"
                  v-for="item in cate_list"
                  :key="item.id"
                >
                  <template #text>
                    <div class="txt">{{ item.title }}</div>
                  </template>
                  <template #icon>
                    <img :src="item.img" class="icon" alt="" />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-swipe-item> -->
          </van-swipe>
        </div>
      </div>
      <div class="hotRecommend">
        <div class="title">
          <span class="biu"></span>
          <div class="text">{{headerTitle}}</div>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" :offset="1" @load="productLoad" >
          <vaeRecommend :recList="product_list" @checkDetail="detailInfo" ></vaeRecommend>
        <!-- <div class="itemContent">
          <div
            class="item"
            @click="detailInfo(item.id)"
            v-for="item in product_list"
            :key="item.id"
          >
            <img :src="item.main_imgs" class="goods" alt="" />
            <div class="goods-info">
              <div class="goods-title two">{{ item.title }}</div>
            </div>
          </div>
        </div> -->
        </van-list>
      </div>
    </div>
    <div class="hotRecommend" v-else>
      <div class="title">
        <span class="biu"></span>
        <div class="text">搜索列表</div>
      </div>
      <vaeRecommend :recList="product_list" @checkDetail="detailInfo" ></vaeRecommend>
      <!-- <div class="itemContent">
        <div
          class="item"
          @click="detailInfo(item.id)"
          v-for="(item, index) in product_list"
          :key="index"
        >
          <img :src="item.main_imgs" class="goods" alt="" />
          <div class="goods-info">
            <div class="goods-title two">{{ item.title }}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { cateList,catezList,productList } from '@/api/studentProduct'
import vaeRecommend from '@/components/hotRecommend.vue'
export default {
  name:'student',
  components: {
    vaeRecommend
  },
  data () {
    return {
      search_ward: '',
      productList: [],
      cate_list: [],
      product_list: [],
      is_show: true,
      startPage:1,
      listObj:{
        type:2,
        page:1,
        search_word:''
      },
      headerTitle:'热门推荐',
      loading: false,
      finished: false,
      countAll:null
    }
  },
  created () {
    this.getList(this.startPage)
    this.getCatezList (this.listObj)
  },
  watch: {
    search_ward: {
      handler (val) {
        if (val == '') {
          this.is_show = true
          this.getList(this.startPage)
          this.listObj.search_word = ''
          this.headerTitle = '热门推荐'
          this.getCatezList (this.listObj)
        }
      }
    }
  },
  methods: {
    // 请求
    async getList (page) {
      const res = await cateList({ type: 2 ,page:page})
      if (res.data.code == 200) {
        this.cate_list = res.data.data
      }
    },
    // 综合
    async getCatezList (obj) {
      const res = await catezList(obj)
      if (res.data.code == 200) {
        res.data.data.list.forEach(element => {
          this.product_list.push(element)
        });
        if(this.product_list.length >= res.data.data.count) {
          this.finished =  true
        }
        this.countAll = res.data.data.count
      }
    },
    // 分页
    productLoad() {
      console.log(this.listObj.page,Math.ceil(this.countAll/10) );
       if(this.listObj.page >= Math.ceil(this.countAll/10) ) {
         this.loading =  false
       }
       this.loading =  true
       setTimeout(() => {
         this.listObj.page++;
        this.getCatezList(this.listObj)
        this.loading =  false
       }, 1000);
      
    },
    search (val) {
      this.listObj.search_word = this.search_ward
      this.getCatezList (this.listObj)
        this.is_show = false
      // const res = await cateList({
      //   search_word: this.search_ward,
      //   type: 2
      // })
      // if (res.data.code === 200) {
      //   this.product_list = res.data.data.product_list
      //   this.is_show = false
      // }
    },
    detailInfo (id) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          id: id
        }
      })
    },
    async classItemList (val) {
      this.product_list = []
      this.headerTitle = val.title
      const obj = {
        cate_id:val.id,
        page:1
      }
      const res = await productList(obj)
      if (res.data.code == 200) {
        if(this.product_list) {
          this.product_list = []
        }
        res.data.data.list.forEach(element => {
          this.product_list.push(element)
        });
        if(this.product_list.length >= res.data.data.count) {
          this.finished =  true
        }
        this.countAll = res.data.data.count
      }
    },
    swipeChange (i) {
      this.getList(i+1)
    }
  }
}
</script>

<style lang="less" scoped>
.student {
  background: #f7f8f9;
  height: calc(100vh - 145px);
  .content {
    .eyeType {
      margin-top: 15px;
      padding: 0 15px;
      .title {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        border-radius: 2px;
        height: 20px;
        display: flex;
        align-items: center;
        .biu {
          width: 4px;
          height: 15px;
          background-color: #2163e9;
          border-radius: 2px;
        }
        .text {
          padding-left: 10px;
        }
      }
      .eyeTypeList {
        height: 150.5px;
        margin-top: 15px;
        border-radius: 10px;
        background-color: #fff;
        overflow: hidden;
        .icon {
          width: 30px;
          height: 30px;
        }
        .txt {
          margin-top: 5px;
          font-size: 12px;
        }
        .txt1 {
          font-size: 11px;
        }
      }
    }
  }
  .hotRecommend {
    margin-top: 15px;
    .title {
      padding: 0 15px;
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      border-radius: 2px;
      height: 20px;
      display: flex;
      align-items: center;
      .biu {
        width: 4px;
        height: 15px;
        background-color: #2163e9;
        border-radius: 2px;
      }
      .text {
        padding-left: 10px;
      }
    }
    .itemContent {
      margin-top: 10px;
    }
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  ::v-deep .van-swipe__indicator--active {
    background-color: #2163e9 !important;
  }
  ::v-deep .van-grid {
    position: relative;
  }
  ::v-deep .van-swipe__indicators {
     position: absolute;
    bottom: -1px;
  }
  ::v-deep .van-swipe__indicator {
    width: 20px;
    height: 3px;
    z-index: 999;
    border-radius: 0% !important;
  }
  ::v-deep .van-grid-item__content {
    padding: 0;
  }
}
</style>
